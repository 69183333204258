
































































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';//获取专栏
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {

    }
})
export default class imgtextstatistics extends Vue {
    [x: string]: any;
    public form: any = {
        pages: 1, size: 10, current: 1, total: 0, push: 0, dele: 0, channelId: '', articleId: '',
        orderName: '', orderType: '', picid: ''
    };
    public datacreateTime: any[] = [];
    private tableData: any[] = [];
    public sortJson: any = {
        readnum: '',//阅读人数
        readproportions: '',//阅读率(%)
        readtimes: '',//阅读次数
        likesnum: '',//点赞人数
        forwardnum: '',//转发人数
        forwardproportions: '',//转发率(%)
    };
    public applications: any[] = [];
    public pushList: any[] = [//是否推送
        { id: 0, name: '全部' },
        { id: 1, name: '是' },
        { id: 2, name: '否' }
    ];
    public deleList: any[] = [//是否删除
        { id: '', name: '全部' },
        { id: 0, name: '否' },
        { id: 1, name: '是' }
    ];
    public loading: Boolean = false;
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.applicationsList();
        await this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this, dataJson = {};
        that.loading = true;
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.endTime = that.datacreateTime[1]
            that.form.startTime = that.datacreateTime[0]
        } else {
            that.form.endTime = ''
            that.form.startTime = ''
        }
        dataJson = {
            size: that.form.size,
            current: that.form.current,
            entity: {
                agentId: that.form.channelId.toString(),//所属应用/专栏
                articleId: Number(that.form.picid),//图文ID
                isPublish: that.form.push,//是否推送
                isDelete: that.form.dele,//是否删除
                orderName: that.form.orderName,//排序字段
                orderType: that.form.orderType,//正序倒序:空值:正序 desc:倒序
                searchStartTime: that.form.startTime,//开始时间
                searchEndTime: that.form.endTime,//结束时间
            }
        }
        const res = await this.statisticsApi.getImgTextList(dataJson);
        that.tableData = res.records;
        this.form.total = res.total;
        this.form.pages = res.pages;
        this.form.size = res.size;
        this.form.current = res.current;
        that.loading = false;
    }
    //获取专栏(这里是应用)
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res
    }
    //导出
    public exportClick(row: any, type: string): void {
        var that = this, data_ = {}, str: any = '';
        if (that.tableData.length <= 0) {
            this.$message({
                type: 'error',
                message: '当前无下载内容！'
            })
            return;
        }
        if (that.datacreateTime.length > 0) {
            this.form.endTime = that.datacreateTime[1]
            this.form.startTime = that.datacreateTime[0]
        } else {
            this.form.endTime = ''
            this.form.startTime = ''
        }
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();
        var timeSend = year + '-' + month + '-' + day;
        if (row == 'all') {
            // 导出全部
            data_ = {
                "agentId": that.form.channelId.toString(),
                "articleId": Number(that.form.picid),
                "isDelete": that.form.dele,//是否删除
                "isPublish": that.form.push,//是否推送
                "orderName": that.form.orderName,
                "orderType": that.form.orderType,//正序倒序:空值:正序 desc:倒序
                "searchEndTime": this.form.endTime,
                "searchStartTime": this.form.startTime
            }
            that.loading = false;
            str = that.splicing(data_)
            this.statisticsApi.ImgTextDownloadAll(str, '图文分析'+timeSend);
            // window.open(document.location.origin + `/api/application/statis/download-list?` + str)

        } else {
            // 根据内容导出
            data_ = {
                "articleId": row.articleId,
                "dwName": type
            }
            that.loading = false;
            str = row.articleId + '/' + type
            // str = that.splicing(data_)
            // window.open(document.location.origin + `/api/application/statis/download/` + row.articleId + '/' + type)
            if (row.createTime != null && row.createTime != undefined && row.createTime != '') {
                if (type == 'l') {
                    this.statisticsApi.ImgTextDownloadSingle(str, row.agentName + '点赞明细' + timeSend);
                } else if (type == 'r') {
                    this.statisticsApi.ImgTextDownloadSingle(str, row.agentName + '阅读明细' + timeSend);
                } else {
                    this.statisticsApi.ImgTextDownloadSingle(str, row.agentName + '转发明细' + timeSend);
                }
            } else {
                if (type == 'l') {
                    this.statisticsApi.ImgTextDownloadSingle(str, row.agentName + '点赞明细'+timeSend);
                } else if (type == 'r') {
                    this.statisticsApi.ImgTextDownloadSingle(str, row.agentName + '阅读明细' +timeSend);
                } else {
                    this.statisticsApi.ImgTextDownloadSingle(str, row.agentName + '转发明细' +timeSend);
                }
            }

        }
    }
    // 导出拼接
    public splicing(data: any) {
        var str = [], strString = '';
        for (var key in data) {
            str.push(key + "=" + data[key])
        }
        strString = str.join('&')
        return strString;
    }
    // 排序点击
    public readingClick(e: any): void {
        var that = this;
        that.form.orderName = e.column.property;
        if (e.order == 'descending') {
            that.form.orderType = 'desc';
        } else if (e.order == "ascending") {
            that.form.orderType = '';
        } else {
            that.form.orderName = '';
            that.form.orderType = '';
        }
        that.form.current = 1;
        this.getList();
    }
    public search() {
        //插件时间格式化
        if (this.datacreateTime && this.datacreateTime.length > 0) {
            this.form.startTime = this.datacreateTime[0];
            this.form.endTime = this.datacreateTime[1];
        } else {
            this.form.startTime = "";
            this.form.endTime = "";
        }
        this.form.current = 1
        this.getList()
    }
    // 重置
    public reset() {
        this.form = {
            size: this.form.size, current: 1,
            push: 0, dele: 0, channelId: '', articleId: '',
            orderName: '', orderType: '', picid: ''
        };
        this.datacreateTime = [];
        (this.$refs.singleTable as HTMLFormElement).clearSort();
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
}
